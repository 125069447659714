import { tenantApi as api } from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    activityLogsGetActivityLogsTenantsTenantIdActivityLogsGet: build.query<
      ActivityLogsGetActivityLogsTenantsTenantIdActivityLogsGetApiResponse,
      ActivityLogsGetActivityLogsTenantsTenantIdActivityLogsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/activity_logs`,
        params: {
          section: queryArg.section,
          section_id: queryArg.sectionId,
          search: queryArg.search,
          page: queryArg.page,
          size: queryArg.size
        }
      })
    }),
    getTenantLoginByEmailTenantsLoginInfoGet: build.query<
      GetTenantLoginByEmailTenantsLoginInfoGetApiResponse,
      GetTenantLoginByEmailTenantsLoginInfoGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/login_info`, params: { email: queryArg.email } })
    }),
    getTenantsTenantsGet: build.query<GetTenantsTenantsGetApiResponse, GetTenantsTenantsGetApiArg>({
      query: (queryArg) => ({ url: `/tenants`, params: { query: queryArg.query } })
    }),
    createTenantTenantsPost: build.mutation<CreateTenantTenantsPostApiResponse, CreateTenantTenantsPostApiArg>({
      query: (queryArg) => ({ url: `/tenants`, method: 'POST', body: queryArg.createTenantDto })
    }),
    getTenantTenantsTenantIdGet: build.query<GetTenantTenantsTenantIdGetApiResponse, GetTenantTenantsTenantIdGetApiArg>(
      {
        query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}` })
      }
    ),
    updateTenantTenantsTenantIdPut: build.mutation<
      UpdateTenantTenantsTenantIdPutApiResponse,
      UpdateTenantTenantsTenantIdPutApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}`, method: 'PUT', body: queryArg.updateTenantDto })
    }),
    getTenantLanguagesTenantsTenantIdAvailableLanguagesGet: build.query<
      GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiResponse,
      GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/available_languages` })
    }),
    getTenantNotesTenantsTenantIdNotesGet: build.query<
      GetTenantNotesTenantsTenantIdNotesGetApiResponse,
      GetTenantNotesTenantsTenantIdNotesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/notes` })
    }),
    createTenantNoteTenantsTenantIdNotesPost: build.mutation<
      CreateTenantNoteTenantsTenantIdNotesPostApiResponse,
      CreateTenantNoteTenantsTenantIdNotesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/notes`,
        method: 'POST',
        body: queryArg.createTenantNoteDto
      })
    }),
    deleteTenantNotesTenantsTenantIdNotesDelete: build.mutation<
      DeleteTenantNotesTenantsTenantIdNotesDeleteApiResponse,
      DeleteTenantNotesTenantsTenantIdNotesDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/notes`, method: 'DELETE' })
    }),
    getTenantNoteTenantsTenantIdNotesTenantNoteIdGet: build.query<
      GetTenantNoteTenantsTenantIdNotesTenantNoteIdGetApiResponse,
      GetTenantNoteTenantsTenantIdNotesTenantNoteIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/notes/${queryArg.tenantNoteId}` })
    }),
    updateTenantNoteTenantsTenantIdNotesTenantNoteIdPut: build.mutation<
      UpdateTenantNoteTenantsTenantIdNotesTenantNoteIdPutApiResponse,
      UpdateTenantNoteTenantsTenantIdNotesTenantNoteIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/notes/${queryArg.tenantNoteId}`,
        method: 'PUT',
        body: queryArg.updateTenantNoteDto
      })
    }),
    deleteTenantNoteTenantsTenantIdNotesTenantNoteIdDelete: build.mutation<
      DeleteTenantNoteTenantsTenantIdNotesTenantNoteIdDeleteApiResponse,
      DeleteTenantNoteTenantsTenantIdNotesTenantNoteIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/notes/${queryArg.tenantNoteId}`, method: 'DELETE' })
    }),
    createJobTenantsTenantIdImportsPost: build.mutation<
      CreateJobTenantsTenantIdImportsPostApiResponse,
      CreateJobTenantsTenantIdImportsPostApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/imports`, method: 'POST', body: queryArg.payload })
    }),
    getActiveJobTenantsTenantIdImportsActiveGet: build.query<
      GetActiveJobTenantsTenantIdImportsActiveGetApiResponse,
      GetActiveJobTenantsTenantIdImportsActiveGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/imports/active` })
    }),
    getJobTenantsTenantIdImportsJobIdGet: build.query<
      GetJobTenantsTenantIdImportsJobIdGetApiResponse,
      GetJobTenantsTenantIdImportsJobIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/imports/${queryArg.jobId}` })
    }),
    handleCommandTenantsTenantIdImportsJobIdPost: build.mutation<
      HandleCommandTenantsTenantIdImportsJobIdPostApiResponse,
      HandleCommandTenantsTenantIdImportsJobIdPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/imports/${queryArg.jobId}`,
        method: 'POST',
        body: queryArg.command
      })
    }),
    usersGetUsersForTenantTenantsTenantIdUsersGet: build.query<
      UsersGetUsersForTenantTenantsTenantIdUsersGetApiResponse,
      UsersGetUsersForTenantTenantsTenantIdUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users`,
        params: {
          roles: queryArg.roles,
          locations: queryArg.locations,
          departments: queryArg.departments,
          positions: queryArg.positions,
          training_ids: queryArg.trainingIds,
          add_trainings: queryArg.addTrainings,
          query: queryArg.query,
          page: queryArg.page,
          size: queryArg.size
        }
      })
    }),
    usersCreateUserTenantsTenantIdUsersPost: build.mutation<
      UsersCreateUserTenantsTenantIdUsersPostApiResponse,
      UsersCreateUserTenantsTenantIdUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users`,
        method: 'POST',
        body: queryArg.createUserDto
      })
    }),
    usersGetUserTenantsTenantIdUsersUserIdGet: build.query<
      UsersGetUserTenantsTenantIdUsersUserIdGetApiResponse,
      UsersGetUserTenantsTenantIdUsersUserIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`,
        params: { add_trainings: queryArg.addTrainings }
      })
    }),
    usersUpdateUserTenantsTenantIdUsersUserIdPut: build.mutation<
      UsersUpdateUserTenantsTenantIdUsersUserIdPutApiResponse,
      UsersUpdateUserTenantsTenantIdUsersUserIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.updateUserDto
      })
    }),
    usersDeleteUserTenantsTenantIdUsersUserIdDelete: build.mutation<
      UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiResponse,
      UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}`, method: 'DELETE' })
    }),
    usersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatch: build.mutation<
      UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiResponse,
      UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}/settings`,
        method: 'PATCH',
        body: queryArg.updateUserSettingsDto
      })
    }),
    usersArchiveUsersTenantsTenantIdArchiveUsersPut: build.mutation<
      UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiResponse,
      UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/archive/users`,
        method: 'PUT',
        body: queryArg.userData
      })
    }),
    usersInviteUsersTenantsTenantIdUsersInvitePost: build.mutation<
      UsersInviteUsersTenantsTenantIdUsersInvitePostApiResponse,
      UsersInviteUsersTenantsTenantIdUsersInvitePostApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/users/invite`, method: 'POST', body: queryArg.ids })
    }),
    usersImportUsersTenantsTenantIdImportUsersPost: build.mutation<
      UsersImportUsersTenantsTenantIdImportUsersPostApiResponse,
      UsersImportUsersTenantsTenantIdImportUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/import/users`,
        method: 'POST',
        body: queryArg.bodyUsersImportUsersTenantsTenantIdImportUsersPost
      })
    }),
    usersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatch: build.mutation<
      UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiResponse,
      UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users/${queryArg.userId}/update-user-status`,
        method: 'PATCH',
        body: queryArg.updateUserStatusDto
      })
    }),
    usersGetUserFiltersTenantsTenantIdUsersViewFiltersGet: build.query<
      UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiResponse,
      UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/users-view/filters`,
        params: { type: queryArg['type'], search: queryArg.search, page: queryArg.page, size: queryArg.size }
      })
    }),
    tenantThemesUpsertLogoTenantsTenantIdThemesLogoPost: build.mutation<
      TenantThemesUpsertLogoTenantsTenantIdThemesLogoPostApiResponse,
      TenantThemesUpsertLogoTenantsTenantIdThemesLogoPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/themes/logo`,
        method: 'POST',
        body: queryArg.bodyTenantThemesUpsertLogoTenantsTenantIdThemesLogoPost
      })
    }),
    tenantThemesDeleteLogoTenantsTenantIdThemesLogoDelete: build.mutation<
      TenantThemesDeleteLogoTenantsTenantIdThemesLogoDeleteApiResponse,
      TenantThemesDeleteLogoTenantsTenantIdThemesLogoDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/themes/logo`, method: 'DELETE' })
    }),
    tenantThemesGetThemeTenantsTenantIdThemeGet: build.query<
      TenantThemesGetThemeTenantsTenantIdThemeGetApiResponse,
      TenantThemesGetThemeTenantsTenantIdThemeGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/theme` })
    }),
    tenantThemesUpsertThemeTenantsTenantIdThemePatch: build.mutation<
      TenantThemesUpsertThemeTenantsTenantIdThemePatchApiResponse,
      TenantThemesUpsertThemeTenantsTenantIdThemePatchApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/theme`, method: 'PATCH', body: queryArg.upsert })
    }),
    synchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPost: build.mutation<
      SynchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPostApiResponse,
      SynchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPostApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/subscriptions/sync`, method: 'POST' })
    }),
    getSubscriptionsForTenantTenantsTenantIdSubscriptionsGet: build.query<
      GetSubscriptionsForTenantTenantsTenantIdSubscriptionsGetApiResponse,
      GetSubscriptionsForTenantTenantsTenantIdSubscriptionsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/subscriptions` })
    }),
    getSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGet: build.query<
      GetSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGetApiResponse,
      GetSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/subscriptions/${queryArg.subscriptionId}` })
    }),
    subscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGet: build.query<
      SubscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGetApiResponse,
      SubscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/subscription_course_views` })
    }),
    subscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGet: build.query<
      SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiResponse,
      SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/courses/${queryArg.courseId}/subscription_course_variants`
      })
    }),
    subscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGet: build.query<
      SubscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGetApiResponse,
      SubscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGetApiArg
    >({
      query: (queryArg) => ({ url: `/v1/tenants/${queryArg.tenantId}/subscription_course_views` })
    }),
    subscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGet: build.query<
      SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiResponse,
      SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/subscriptions/${queryArg.subscriptionId}/subscription_courses`
      })
    }),
    trainingsGetTrainingsTenantsTenantIdTrainingsGet: build.query<
      TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiResponse,
      TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings` })
    }),
    trainingsCreateTrainingTenantsTenantIdTrainingsPost: build.mutation<
      TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiResponse,
      TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings`,
        method: 'POST',
        body: queryArg.createTrainingDto
      })
    }),
    trainingsGetMyTrainingsTenantsTenantIdMeTrainingsGet: build.query<
      TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiResponse,
      TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/trainings` })
    }),
    trainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGet: build.query<
      TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiResponse,
      TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}` })
    }),
    trainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePut: build.mutation<
      TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiResponse,
      TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/archive`,
        method: 'PUT'
      })
    }),
    trainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGet: build.query<
      TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiResponse,
      TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/details` })
    }),
    trainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGet: build.query<
      TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiResponse,
      TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/participations`,
        params: {
          training_cycle_id: queryArg.trainingCycleId,
          only_completed: queryArg.onlyCompleted,
          page: queryArg.page,
          size: queryArg.size
        }
      })
    }),
    trainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPost: build.mutation<
      TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiResponse,
      TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}/start`,
        method: 'POST',
        body: queryArg.trainingStartInDto
      })
    }),
    trainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPost: build.mutation<
      TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiResponse,
      TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/participations/export`,
        method: 'POST',
        body: queryArg.trainingParticipationsExportInDto
      })
    }),
    trainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGet: build.query<
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse,
      TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/training_participations` })
    }),
    trainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPost: build.mutation<
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse,
      TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/training_users`,
        method: 'POST',
        body: queryArg.payloads,
        params: { send_invite: queryArg.sendInvite }
      })
    }),
    trainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGet: build.query<
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse,
      TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users`,
        params: { only_completed: queryArg.onlyCompleted, training_cycle_id: queryArg.trainingCycleId }
      })
    }),
    trainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePut: build.mutation<
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse,
      TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/archive`,
        method: 'PUT'
      })
    }),
    trainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPut: build.mutation<
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse,
      TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/unassign`,
        method: 'PUT',
        body: queryArg.unassignTrainingUserInDto
      })
    }),
    trainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGet: build.query<
      TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiResponse,
      TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles` })
    }),
    trainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGet: build.query<
      TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiResponse,
      TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}`
      })
    }),
    notificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGet: build.query<
      NotificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGetApiResponse,
      NotificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/notification_templates`,
        params: { notification_type: queryArg.notificationType, language_tag: queryArg.languageTag }
      })
    }),
    notificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPost:
      build.mutation<
        NotificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPostApiResponse,
        NotificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/notification_templates`,
          method: 'POST',
          body: queryArg.createNotificationTemplateForTenantDto
        })
      }),
    notificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGet:
      build.query<
        NotificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGetApiResponse,
        NotificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/notification_templates/${queryArg.id}` })
      }),
    notificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPut:
      build.mutation<
        NotificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPutApiResponse,
        NotificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/notification_templates/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateNotificationTemplateForTenantDto
        })
      }),
    notificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDelete:
      build.mutation<
        NotificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDeleteApiResponse,
        NotificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/notification_templates/${queryArg.id}`,
          method: 'DELETE'
        })
      }),
    emailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPost: build.mutation<
      EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiResponse,
      EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/emails`,
        method: 'POST',
        body: queryArg.usersEmailDto
      })
    }),
    emailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPost: build.mutation<
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse,
      EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/users/me/emails`,
        method: 'POST',
        body: queryArg.emailDto
      })
    }),
    odooGetTenantPartnerTenantsTenantIdOdooPartnerGet: build.query<
      OdooGetTenantPartnerTenantsTenantIdOdooPartnerGetApiResponse,
      OdooGetTenantPartnerTenantsTenantIdOdooPartnerGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/odoo_partner`,
        params: { extended: queryArg.extended }
      })
    }),
    certificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGet: build.query<
      CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiResponse,
      CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/me/certificates` })
    }),
    certificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGet:
      build.query<
        CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiResponse,
        CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}/trainings/${queryArg.trainingCode}/training_cycles/${queryArg.trainingCycleId}/zipped-certificates`
        })
      }),
    getManagedTenantsManagedTenantsGet: build.query<
      GetManagedTenantsManagedTenantsGetApiResponse,
      GetManagedTenantsManagedTenantsGetApiArg
    >({
      query: (queryArg) => ({ url: `/managed/tenants`, params: { query: queryArg.query } })
    })
  }),
  overrideExisting: true
})
export { injectedRtkApi as enhancedApi }
export type ActivityLogsGetActivityLogsTenantsTenantIdActivityLogsGetApiResponse =
  /** status 200 Successful Response */ PageUserActivityLogDto | Page
export type ActivityLogsGetActivityLogsTenantsTenantIdActivityLogsGetApiArg = {
  tenantId: string
  /** Section e.g training */
  section?: Section
  /** Section ID e.g training_id for training section */
  sectionId?: string
  /** Global search query to filter logs */
  search?: string
  page?: number
  size?: number
}
export type GetTenantLoginByEmailTenantsLoginInfoGetApiResponse =
  /** status 200 Successful Response */ TenantLoginInfoDto
export type GetTenantLoginByEmailTenantsLoginInfoGetApiArg = {
  email: string
}
export type GetTenantsTenantsGetApiResponse = /** status 200 Successful Response */ TenantDto[]
export type GetTenantsTenantsGetApiArg = {
  query?: string
}
export type CreateTenantTenantsPostApiResponse = /** status 201 Successful Response */ TenantDto
export type CreateTenantTenantsPostApiArg = {
  createTenantDto: CreateTenantDto
}
export type GetTenantTenantsTenantIdGetApiResponse = /** status 200 Successful Response */ TenantDto
export type GetTenantTenantsTenantIdGetApiArg = {
  tenantId: string
}
export type UpdateTenantTenantsTenantIdPutApiResponse = /** status 200 Successful Response */ TenantDto
export type UpdateTenantTenantsTenantIdPutApiArg = {
  tenantId: string
  updateTenantDto: UpdateTenantDto
}
export type GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiResponse =
  /** status 200 Successful Response */ LanguageDto[]
export type GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiArg = {
  tenantId: string
}
export type GetTenantNotesTenantsTenantIdNotesGetApiResponse = /** status 200 Successful Response */ TenantNoteDto[]
export type GetTenantNotesTenantsTenantIdNotesGetApiArg = {
  tenantId: string
}
export type CreateTenantNoteTenantsTenantIdNotesPostApiResponse = /** status 201 Successful Response */ TenantNoteDto
export type CreateTenantNoteTenantsTenantIdNotesPostApiArg = {
  tenantId: string
  createTenantNoteDto: CreateTenantNoteDto
}
export type DeleteTenantNotesTenantsTenantIdNotesDeleteApiResponse = /** status 204 Successful Response */ void
export type DeleteTenantNotesTenantsTenantIdNotesDeleteApiArg = {
  tenantId: string
}
export type GetTenantNoteTenantsTenantIdNotesTenantNoteIdGetApiResponse =
  /** status 200 Successful Response */ TenantNoteDto
export type GetTenantNoteTenantsTenantIdNotesTenantNoteIdGetApiArg = {
  tenantId: string
  tenantNoteId: string
}
export type UpdateTenantNoteTenantsTenantIdNotesTenantNoteIdPutApiResponse =
  /** status 200 Successful Response */ TenantNoteDto
export type UpdateTenantNoteTenantsTenantIdNotesTenantNoteIdPutApiArg = {
  tenantId: string
  tenantNoteId: string
  updateTenantNoteDto: UpdateTenantNoteDto
}
export type DeleteTenantNoteTenantsTenantIdNotesTenantNoteIdDeleteApiResponse =
  /** status 204 Successful Response */ void
export type DeleteTenantNoteTenantsTenantIdNotesTenantNoteIdDeleteApiArg = {
  tenantId: string
  tenantNoteId: string
}
export type CreateJobTenantsTenantIdImportsPostApiResponse = /** status 201 Successful Response */ ImportJob
export type CreateJobTenantsTenantIdImportsPostApiArg = {
  tenantId: string
  payload: {
    [key: string]: {
      [key: string]: string
    }
  }
}
export type GetActiveJobTenantsTenantIdImportsActiveGetApiResponse = unknown
export type GetActiveJobTenantsTenantIdImportsActiveGetApiArg = {
  tenantId: string
}
export type GetJobTenantsTenantIdImportsJobIdGetApiResponse = /** status 200 Successful Response */ ImportJob
export type GetJobTenantsTenantIdImportsJobIdGetApiArg = {
  jobId: string
  tenantId: string
}
export type HandleCommandTenantsTenantIdImportsJobIdPostApiResponse = unknown
export type HandleCommandTenantsTenantIdImportsJobIdPostApiArg = {
  jobId: string
  tenantId: string
  command: Command
}
export type UsersGetUsersForTenantTenantsTenantIdUsersGetApiResponse =
  /** status 200 Successful Response */
  PageUserWithTrainingsDto | PageUserDto
export type UsersGetUsersForTenantTenantsTenantIdUsersGetApiArg = {
  tenantId: string
  /** The required roles for the queried users, passed as an array of role names. Returned users will match all given roles. */
  roles?: string[]
  /** The locations to filter the queried users. */
  locations?: string[]
  /** The departments to filter the queried users. */
  departments?: string[]
  /** The positions to filter the queried users. */
  positions?: string[]
  /** The trainings to filter the queried users. */
  trainingIds?: string[]
  /** Toggles adding trainings field to the returned response */
  addTrainings?: boolean
  /** A search string to filter returned users */
  query?: string
  page?: number
  size?: number
}
export type UsersCreateUserTenantsTenantIdUsersPostApiResponse = /** status 201 Successful Response */ UserDto
export type UsersCreateUserTenantsTenantIdUsersPostApiArg = {
  tenantId: string
  createUserDto: CreateUserDto
}
export type UsersGetUserTenantsTenantIdUsersUserIdGetApiResponse =
  /** status 200 Successful Response */
  UserWithTrainingsDto | UserDto
export type UsersGetUserTenantsTenantIdUsersUserIdGetApiArg = {
  tenantId: string
  userId: string
  /** Toggles adding trainings field to the returned response */
  addTrainings?: boolean
}
export type UsersUpdateUserTenantsTenantIdUsersUserIdPutApiResponse = /** status 200 Successful Response */ UserDto
export type UsersUpdateUserTenantsTenantIdUsersUserIdPutApiArg = {
  tenantId: string
  userId: string
  updateUserDto: UpdateUserDto
}
export type UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiResponse = /** status 204 Successful Response */ void
export type UsersDeleteUserTenantsTenantIdUsersUserIdDeleteApiArg = {
  tenantId: string
  userId: string
}
export type UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiResponse =
  /** status 200 Successful Response */ UserDto
export type UsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchApiArg = {
  tenantId: string
  userId: string
  updateUserSettingsDto: UpdateUserSettingsDto
}
export type UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiResponse = /** status 200 Successful Response */ void
export type UsersArchiveUsersTenantsTenantIdArchiveUsersPutApiArg = {
  tenantId: string
  userData: {
    [key: string]: string[]
  }
}
export type UsersInviteUsersTenantsTenantIdUsersInvitePostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string
}
export type UsersInviteUsersTenantsTenantIdUsersInvitePostApiArg = {
  tenantId: string
  ids: string[]
}
export type UsersImportUsersTenantsTenantIdImportUsersPostApiResponse =
  /** status 200 Successful Response */ ImportUserReportDto
export type UsersImportUsersTenantsTenantIdImportUsersPostApiArg = {
  tenantId: string
  bodyUsersImportUsersTenantsTenantIdImportUsersPost: BodyUsersImportUsersTenantsTenantIdImportUsersPost
}
export type UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiResponse =
  /** status 200 Successful Response */ boolean
export type UsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchApiArg = {
  userId: string
  tenantId: string
  updateUserStatusDto: UpdateUserStatusDto
}
export type UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiResponse =
  /** status 200 Successful Response */
  PageUserFilterModelDto | Page
export type UsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetApiArg = {
  tenantId: string
  /** Type of filter to apply */
  type: FilterType
  /** Search string to filter results */
  search?: string
  page?: number
  size?: number
}
export type TenantThemesUpsertLogoTenantsTenantIdThemesLogoPostApiResponse =
  /** status 200 Successful Response */ UploadLogoDto
export type TenantThemesUpsertLogoTenantsTenantIdThemesLogoPostApiArg = {
  tenantId: string
  bodyTenantThemesUpsertLogoTenantsTenantIdThemesLogoPost: BodyTenantThemesUpsertLogoTenantsTenantIdThemesLogoPost
}
export type TenantThemesDeleteLogoTenantsTenantIdThemesLogoDeleteApiResponse =
  /** status 204 Successful Response */ void
export type TenantThemesDeleteLogoTenantsTenantIdThemesLogoDeleteApiArg = {
  tenantId: string
}
export type TenantThemesGetThemeTenantsTenantIdThemeGetApiResponse =
  /** status 200 Successful Response */ TenantThemeDto
export type TenantThemesGetThemeTenantsTenantIdThemeGetApiArg = {
  tenantId: string
}
export type TenantThemesUpsertThemeTenantsTenantIdThemePatchApiResponse =
  /** status 200 Successful Response */ TenantThemeDto
export type TenantThemesUpsertThemeTenantsTenantIdThemePatchApiArg = {
  tenantId: string
  upsert: Upsert
}
export type SynchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPostApiResponse =
  /** status 200 Successful Response */ SubscriptionSyncResultDto
export type SynchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPostApiArg = {
  tenantId: string
}
export type GetSubscriptionsForTenantTenantsTenantIdSubscriptionsGetApiResponse =
  /** status 200 Successful Response */ SubscriptionDto[]
export type GetSubscriptionsForTenantTenantsTenantIdSubscriptionsGetApiArg = {
  tenantId: string
}
export type GetSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGetApiResponse =
  /** status 200 Successful Response */ SubscriptionDto
export type GetSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGetApiArg = {
  tenantId: string
  subscriptionId: string
}
export type SubscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGetApiResponse =
  /** status 200 Successful Response */ SubscriptionCourseViewDto[]
export type SubscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGetApiArg = {
  tenantId: string
}
export type SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiResponse =
  /** status 200 Successful Response */ CourseVariantDto[]
export type SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiArg = {
  tenantId: string
  courseId: string
}
export type SubscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGetApiResponse =
  /** status 200 Successful Response */ GroupedSubscriptionCourseViewDto[]
export type SubscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGetApiArg = {
  tenantId: string
}
export type SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiResponse =
  /** status 200 Successful Response */ SubscriptionCoursesDto
export type SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiArg =
  {
    subscriptionId: string
    tenantId: string
  }
export type TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiResponse =
  /** status 200 Successful Response */ TrainingWithTotalStatisticsDto[]
export type TrainingsGetTrainingsTenantsTenantIdTrainingsGetApiArg = {
  tenantId: string
}
export type TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiResponse =
  /** status 201 Successful Response */ TrainingDto
export type TrainingsCreateTrainingTenantsTenantIdTrainingsPostApiArg = {
  tenantId: string
  createTrainingDto: CreateTrainingDto
}
export type TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiResponse =
  /** status 200 Successful Response */ TrainingDto[]
export type TrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetApiArg = {
  tenantId: string
}
export type TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiResponse =
  /** status 200 Successful Response */ TrainingDto
export type TrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiResponse =
  /** status 200 Successful Response */ void
export type TrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiResponse =
  /** status 200 Successful Response */ TrainingDetailsDto
export type TrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiResponse =
  /** status 200 Successful Response */ PageTrainingParticipationDto | Page
export type TrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetApiArg = {
  tenantId: string
  trainingCode: string
  /** UUID of the training cycle. Defaults to "current" training cycle if not specified. */
  trainingCycleId?: string
  /** If set to `true`, the endpoint will return only completed participations. Defaults to `false`. */
  onlyCompleted?: boolean
  page?: number
  size?: number
}
export type TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiResponse =
  /** status 200 Successful Response */ TrainingStartOutDto
export type TrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostApiArg = {
  tenantId: string
  trainingCycleId: string
  trainingCode: string
  trainingStartInDto: TrainingStartInDto
}
export type TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiResponse =
  /** status 200 Successful Response */ TrainingParticipationsExportOutDto
export type TrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostApiArg = {
  tenantId: string
  trainingCode: string
  trainingParticipationsExportInDto: TrainingParticipationsExportInDto
}
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiResponse =
  /** status 200 Successful Response */ CurrentUserTrainingParticipationDto[]
export type TrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetApiArg = {
  tenantId: string
}
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiResponse =
  /** status 200 Successful Response */ GroupedTrainingUsersDto[]
export type TrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostApiArg = {
  tenantId: string
  /** Toggles sending training invitation emails */
  sendInvite?: boolean
  payloads: CreateTrainingUsersDto[]
}
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiResponse =
  /** status 200 Successful Response */ TrainingUserDto[]
export type TrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetApiArg = {
  tenantId: string
  trainingCode: string
  onlyCompleted?: boolean
  trainingCycleId?: string
}
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiResponse =
  /** status 200 Successful Response */ ArchiveTrainingUserDto[]
export type TrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiResponse =
  /** status 200 Successful Response */ UnassignTrainingUserOutDto
export type TrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutApiArg = {
  tenantId: string
  trainingCode: string
  unassignTrainingUserInDto: UnassignTrainingUserInDto
}
export type TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiResponse =
  /** status 200 Successful Response */ TrainingCycleDto[]
export type TrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetApiArg = {
  tenantId: string
  trainingCode: string
}
export type TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiResponse =
  /** status 200 Successful Response */ TrainingCycleDto
export type TrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetApiArg = {
  trainingCycleId: string
  tenantId: string
  trainingCode: string
}
export type NotificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGetApiResponse =
  /** status 200 Successful Response */ NotificationTemplateForTenantDto[]
export type NotificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGetApiArg =
  {
    tenantId: string
    notificationType?: string
    languageTag?: string
  }
export type NotificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPostApiResponse =
  /** status 201 Successful Response */ NotificationTemplateForTenantDto
export type NotificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPostApiArg =
  {
    tenantId: string
    createNotificationTemplateForTenantDto: CreateNotificationTemplateForTenantDto
  }
export type NotificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGetApiResponse =
  /** status 200 Successful Response */ NotificationTemplateForTenantDto
export type NotificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGetApiArg =
  {
    tenantId: string
    id: string
  }
export type NotificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPutApiResponse =
  /** status 200 Successful Response */ NotificationTemplateForTenantDto
export type NotificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPutApiArg =
  {
    tenantId: string
    id: string
    updateNotificationTemplateForTenantDto: UpdateNotificationTemplateForTenantDto
  }
export type NotificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDeleteApiResponse =
  /** status 200 Successful Response */ void | /** status 204 No Content */ void
export type NotificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDeleteApiArg =
  {
    tenantId: string
    id: string
  }
export type EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiResponse =
  /** status 200 Successful Response */ any
export type EmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostApiArg = {
  tenantId: string
  trainingCode: string
  usersEmailDto: UsersEmailDto
}
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiResponse =
  /** status 200 Successful Response */ any
export type EmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostApiArg = {
  tenantId: string
  trainingCode: string
  emailDto: EmailDto
}
export type OdooGetTenantPartnerTenantsTenantIdOdooPartnerGetApiResponse =
  /** status 200 Successful Response */ OdooExtendedPartnerDto
export type OdooGetTenantPartnerTenantsTenantIdOdooPartnerGetApiArg = {
  tenantId: string
  extended?: boolean
}
export type CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiResponse =
  /** status 200 Successful Response */ CertificateDto[]
export type CertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetApiArg = {
  tenantId: string
}
export type CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiResponse =
  /** status 200 Successful Response */ DownloadCertificateDto
export type CertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetApiArg =
  {
    trainingCycleId: string
    tenantId: string
    trainingCode: string
  }
export type GetManagedTenantsManagedTenantsGetApiResponse = /** status 200 Successful Response */ TenantDto[]
export type GetManagedTenantsManagedTenantsGetApiArg = {
  query?: string
}
export type TenantLogDto = {
  id: string
  name: string
}
export type UserLogDto = {
  id: string
  email: string
  email_verified: boolean
  roles: string[]
  first_name: string
  last_name: string
}
export type TrainingLogDto = {
  id: string
  code: string
  internal_name: string
}
export type TrainingParticipationLogDto = {
  id: string
  started_at?: string
  completed_at?: string
  course_language_tag?: string
  course_form_of_address?: string
}
export type TrainingCycleLogDto = {
  id: string
  training_id: string
  start_date: string
  end_date: string
  due_date?: string
}
export type ActivityLogDataDto = {
  tenant?: TenantLogDto
  user?: UserLogDto
  training?: TrainingLogDto
  participation?: TrainingParticipationLogDto
  training_cycle?: TrainingCycleLogDto
}
export type UserActivityLogDto = {
  id: string
  tenant_id: string
  user_id: string
  training_id?: string
  full_name?: string
  roles?: string
  email?: string
  data?: string
  typed_data?: ActivityLogDataDto | ActivityLogDataDto[]
  action: string
  timestamp: string
}
export type PageUserActivityLogDto = {
  items: UserActivityLogDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type Page = {
  items: any[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type Section = 'Users' | 'Trainings' | 'Courses' | 'Tenants'
export type TenantLoginInfoDto = {
  id: string
  subdomain: string
}
export type TenantDto = {
  id: string
  name: string
  subdomain: string
  details: string
  odoo_partner_id: number
  default_language_tag: string
}
export type CreateTenantDto = {
  name: string
  subdomain: string
  odoo_partner_id: number
  details?: string
  default_language_tag?: string
}
export type UpdateTenantDto = {
  name: string
  subdomain: string
  odoo_partner_id: number
  details?: string
  default_language_tag: string
}
export type LanguageDto = {
  tag: string
  display_name: string
  display_in_ui: boolean
}
export type TenantNoteDto = {
  id: string
  tenant_id: string
  timestamp: string
  user_id: string
  user_name: string
  text: string
  pinned_timestamp?: string
}
export type CreateTenantNoteDto = {
  text: string
  is_pinned?: boolean
}
export type UpdateTenantNoteDto = {
  text: string
  pinned_timestamp?: string
}
export type State = 'cancelled' | 'validating' | 'validated' | 'importing' | 'finished'
export type ImportJob = {
  id: string
  state: State
  rows: number
  validation_messages: {
    [key: string]: string[]
  }
  import_error_messages: {
    [key: string]: string
  }
}
export type Command = 'import'
export type ValidUserRoles = 'lawpilots_admin' | 'customer_success' | 'customer_manager' | 'participant'
export type TrainingStatus = 'active' | 'inactive'
export type TrainingReminderType = 'automated' | 'never'
export type TrainingReminderModel = {
  type?: TrainingReminderType
  schedule_in_days?: number
  last_sent_at?: string
}
export type TrainingDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  course_title?: string
  start_date: string
  end_date?: string
  due_date_in_weeks: number
  recurring_interval_in_months: number
  status: TrainingStatus
  reminder: TrainingReminderModel
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type UserWithTrainingsDto = {
  first_name: string
  last_name: string
  id: string
  email: string
  email_verified?: boolean
  updated_at: string
  invited_at?: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag: string
  roles: ValidUserRoles[]
  trainings: TrainingDto[]
}
export type PageUserWithTrainingsDto = {
  items: UserWithTrainingsDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type UserDto = {
  first_name: string
  last_name: string
  id: string
  email: string
  email_verified?: boolean
  updated_at: string
  invited_at?: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag: string
  roles: ValidUserRoles[]
}
export type PageUserDto = {
  items: UserDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type ValidCreateOrUpdateUserRoles = 'customer_manager' | 'participant'
export type CreateUserDto = {
  first_name: string
  last_name: string
  email: string
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag?: string
  roles: ValidCreateOrUpdateUserRoles[]
  email_verified?: boolean
}
export type UpdateUserDto = {
  department?: string
  location?: string
  employee_id?: string
  position?: string
  language_tag?: string
  roles?: ValidCreateOrUpdateUserRoles[]
  first_name?: string
  last_name?: string
}
export type UpdateUserSettingsDto = {
  language_tag?: string
}
export type UserImportEvent = 'UserRecordCreated' | 'UserRecordUpdated' | 'UserRecordUnchanged' | 'UserRecordFailed'
export type CsvRowReportDto = {
  row: number
  import_event: UserImportEvent
  user: UserDto
}
export type CsvErrorCode =
  | 'EMPTY_CSV'
  | 'PARSING_ERROR'
  | 'FILE_NOT_FOUND'
  | 'UNEXPECTED_ERROR'
  | 'MISSING_REQUIRED_FIELD'
  | 'MISSING_HEADER'
  | 'INVALID_FIELD'
  | 'INVALID_FILE_TYPE'
  | 'FILE_TOO_LARGE'
  | 'USER_ALREADY_ARCHIVED'
export type CsvRowError = {
  row: number
  column?: string
  import_event: UserImportEvent
  code: CsvErrorCode
  reason?: string
}
export type ImportUserReportDto = {
  total_no_of_rows?: number
  no_of_successful_rows?: number
  no_of_failed_rows?: number
  created_rows?: CsvRowReportDto[]
  updated_rows?: CsvRowReportDto[]
  unchanged_rows?: CsvRowReportDto[]
  errors?: CsvRowError[]
  grouped_errors?: {
    [key: string]: CsvRowError[]
  }
}
export type BodyUsersImportUsersTenantsTenantIdImportUsersPost = {
  delimiter?: string
  header_mapping?: string
  encoding?: string
  users_csv: Blob
}
export type UpdateUserStatusDto = {
  email_verified: boolean
}
export type UserFilterModelDto = {
  name: string
  value: string
}
export type PageUserFilterModelDto = {
  items: UserFilterModelDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type FilterType = 'department' | 'role' | 'course' | 'position' | 'location'
export type UploadLogoDto = {
  url?: string
}
export type BodyTenantThemesUpsertLogoTenantsTenantIdThemesLogoPost = {
  logo: Blob
}
export type TenantThemeDto = {
  color?: string
  logo_url?: string
}
export type Upsert = {
  color?: string
}
export type SubscriptionSyncResultDto = {
  number_of_subscriptions: number
}
export type SubscriptionDto = {
  id: string
  tenant_id: string
  code: string
  name: string
  start_date: string
  end_date?: string
  license_start_date: string
  recurring_rule_type: string
  recurring_interval: number
  recurring_next_date: string
  status: string
  created_at: string
  odoo_subscription_id: number
}
export type FormOfAddress = 'formal' | 'informal'
export type CourseVariantModel = {
  created_at?: string
  updated_at?: string
  archived_at?: string
  id: string
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_tag: string
  language_display_name: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
  course_id: string
  lmdh_version_guid?: string
  lmdh_accessibility?: boolean
  tenant_name?: string
}
export type SubscriptionCourseViewDto = {
  subscription_id: string
  line_item_id: string
  course_id: string
  subscription_code: string
  subscription_status: string
  subscription_start_date?: string
  subscription_end_date?: string
  recurring_rule_type: string
  recurring_interval: number
  recurring_interval_in_months: number
  licenses_per_period: number
  licenses_used: number
  license_start_date: string
  license_status: boolean
  license_validity: string
  upcoming_expiration?: string
  course_code: string
  course_name: string
  course_variants: CourseVariantModel[]
}
export type CourseVariantDto = {
  id: string
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_display_name: string
  language_tag: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
  tenant_name?: string
  created_at?: string
  updated_at?: string
}
export type GroupedSubscriptionCourseViewDto = {
  subscription_id: string
  subscription_code: string
  license_status: boolean
  license_start_date: string
  subscription_course_views: SubscriptionCourseViewDto[]
}
export type CourseWithCourseVariantsDto = {
  id: string
  name: string
  max_licenses: number
  variants: CourseVariantModel[]
}
export type SubscriptionCoursesDto = {
  id: string
  code: string
  recurring_rule_type: string
  recurring_interval: number
  recurring_interval_in_months: number
  license_start_date: string
  courses: CourseWithCourseVariantsDto[]
}
export type TrainingWithTotalStatisticsDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  course_title?: string
  start_date: string
  end_date?: string
  due_date_in_weeks: number
  recurring_interval_in_months: number
  status: TrainingStatus
  reminder: TrainingReminderModel
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  archived_at?: string
  total_participations: number
  completion_percentage: number
}
export type CreateTrainingDto = {
  course_id: string
  subscription_id: string
  internal_name: string
  due_date_in_weeks: number
  reminder: TrainingReminderModel
}
export type TrainingDetailsTrainingDto = {
  id: string
  course_id: string
  code: string
  internal_name: string
  course_title?: string
}
export type TrainingDetailsTrainingCycleDto = {
  id: string
  start_date: string
  end_date: string
}
export type TrainingDetailsStatisticsDto = {
  total_participations: number
  completed_participations: number
  completion_percentage: number
}
export type TrainingDetailsLicensesDto = {
  total: number
  used: number
}
export type TrainingDetailsDto = {
  training: TrainingDetailsTrainingDto
  current_training_cycle: TrainingDetailsTrainingCycleDto
  training_cycles: TrainingDetailsTrainingCycleDto[]
  statistics: TrainingDetailsStatisticsDto
  licenses: TrainingDetailsLicensesDto
}
export type TrainingParticipationStatus = 'invited' | 'in_progress' | 'completed'
export type TrainingParticipantStatus = 'active' | 'inactive'
export type TrainingParticipationDto = {
  id: string
  name: string
  email: string
  employee_id?: string
  department?: string
  location?: string
  position?: string
  training_status?: TrainingParticipationStatus
  last_contact?: string
  repeat?: string
  status?: TrainingParticipantStatus
  language_tag?: string
  form_of_address?: FormOfAddress
  training_user_id: string
  certificate_url?: string
  completed_at?: string
}
export type PageTrainingParticipationDto = {
  items: TrainingParticipationDto[]
  total?: number
  page?: number
  size?: number
  pages?: number
}
export type TrainingStartOutDto = {
  courseplay_url: string
}
export type TrainingStartInDto = {
  course_variant_id: string
}
export type TrainingParticipationsExportOutDto = {
  success: boolean
  url?: string
}
export type TrainingParticipationsExportInDto = {
  training_cycle_id?: string
  only_completed?: boolean
  language_tag?: string
}
export type CurrentUserTrainingParticipationDto = {
  id: string
  training_code: string
  training_internal_name: string
  training_start_date: string
  training_end_date?: string
  training_recurring_interval: number
  training_description: string
  training_status?: TrainingParticipationStatus
  repeat: string
  training_user_id: string
  training_cycle_id: string
  course_id: string
  course_duration_in_minutes: number
  courseplay_url?: string
}
export type TrainingUserDto = {
  id: string
  tenant_id: string
  training_id: string
  user_id: string
  completed_on?: string
  created_at?: string
  updated_at?: string
  archived_at?: string
}
export type GroupedTrainingUsersDto = {
  tenant_id: string
  training_id: string
  users: (TrainingUserDto | string)[]
  errors?: string[]
  is_successful: boolean
}
export type CreateTrainingUsersDto = {
  training_id: string
  user_ids: string[]
}
export type ArchiveTrainingUserDto = {
  user_id: string
}
export type UnassignTrainingUserOutDto = {
  training_user_id: string
}
export type UnassignTrainingUserInDto = {
  training_user_id: string
}
export type TrainingCycleDto = {
  id: string
  training_id: string
  sequence_number: number
  start_date: string
  end_date: string
  due_date?: string
  avendoo_course_assignment_id?: string
  created_at: string
  updated_at: string
  archived_at?: string
}
export type TemplateType = 'tenant' | 'global'
export type TemplateTrainingReminderDto = {
  type?: string
  schedule_in_days?: number
}
export type TemplateTrainingDto = {
  id: string
  tenant_id: string
  course_id: string
  subscription_id: string
  code: string
  internal_name: string
  start_date: string
  end_date?: string
  recurring_interval_in_months: number
  status: string
  duration_in_minutes: number
  created_at?: string
  updated_at?: string
  due_date_in_weeks: number
  reminder: TemplateTrainingReminderDto
}
export type NotificationTemplateForTenantDto = {
  created_at?: string
  updated_at?: string
  archived_at?: string
  id: string
  notification_type: string
  language_tag: string
  message_template: string
  title: string
  tenant_id: string
  template_type?: TemplateType
  trainings: TemplateTrainingDto[]
}
export type CreateNotificationTemplateForTenantDto = {
  notification_type: string
  language_tag: string
  message_template: string
  title: string
  training_ids?: string[]
}
export type UpdateNotificationTemplateForTenantDto = {
  message_template: string
  title: string
  training_ids: string[]
}
export type UsersEmailDto = {
  notification_type: string
  language_tag: string
  user_ids: string[]
}
export type EmailDto = {
  notification_type: string
  language_tag: string
}
export type OdooExtendedPartnerDto = {
  id: number
  name: string
  lang: string
  email?: string
  website_url?: string
  email_formatted?: string
  child_ids?: any
  contact_address?: string
}
export type CertificateDto = {
  id: string
  training_code: string
  training_internal_name: string
  obtained_on: string
  url: string
}
export type DownloadCertificateDto = {
  url: string
}
export const {
  usePrefetch,
  useActivityLogsGetActivityLogsTenantsTenantIdActivityLogsGetQuery,
  useGetTenantLoginByEmailTenantsLoginInfoGetQuery,
  useGetTenantsTenantsGetQuery,
  useCreateTenantTenantsPostMutation,
  useGetTenantTenantsTenantIdGetQuery,
  useUpdateTenantTenantsTenantIdPutMutation,
  useGetTenantLanguagesTenantsTenantIdAvailableLanguagesGetQuery,
  useGetTenantNotesTenantsTenantIdNotesGetQuery,
  useCreateTenantNoteTenantsTenantIdNotesPostMutation,
  useDeleteTenantNotesTenantsTenantIdNotesDeleteMutation,
  useGetTenantNoteTenantsTenantIdNotesTenantNoteIdGetQuery,
  useUpdateTenantNoteTenantsTenantIdNotesTenantNoteIdPutMutation,
  useDeleteTenantNoteTenantsTenantIdNotesTenantNoteIdDeleteMutation,
  useCreateJobTenantsTenantIdImportsPostMutation,
  useGetActiveJobTenantsTenantIdImportsActiveGetQuery,
  useGetJobTenantsTenantIdImportsJobIdGetQuery,
  useHandleCommandTenantsTenantIdImportsJobIdPostMutation,
  useUsersGetUsersForTenantTenantsTenantIdUsersGetQuery,
  useUsersCreateUserTenantsTenantIdUsersPostMutation,
  useUsersGetUserTenantsTenantIdUsersUserIdGetQuery,
  useUsersUpdateUserTenantsTenantIdUsersUserIdPutMutation,
  useUsersDeleteUserTenantsTenantIdUsersUserIdDeleteMutation,
  useUsersUpdateUserSettingsTenantsTenantIdUsersUserIdSettingsPatchMutation,
  useUsersArchiveUsersTenantsTenantIdArchiveUsersPutMutation,
  useUsersInviteUsersTenantsTenantIdUsersInvitePostMutation,
  useUsersImportUsersTenantsTenantIdImportUsersPostMutation,
  useUsersUpdateUserStatusTenantsTenantIdUsersUserIdUpdateUserStatusPatchMutation,
  useUsersGetUserFiltersTenantsTenantIdUsersViewFiltersGetQuery,
  useTenantThemesUpsertLogoTenantsTenantIdThemesLogoPostMutation,
  useTenantThemesDeleteLogoTenantsTenantIdThemesLogoDeleteMutation,
  useTenantThemesGetThemeTenantsTenantIdThemeGetQuery,
  useTenantThemesUpsertThemeTenantsTenantIdThemePatchMutation,
  useSynchronizeSubscriptionsTenantsTenantIdSubscriptionsSyncPostMutation,
  useGetSubscriptionsForTenantTenantsTenantIdSubscriptionsGetQuery,
  useGetSubscriptionTenantsTenantIdSubscriptionsSubscriptionIdGetQuery,
  useSubscriptionsGetCourseViewTenantsTenantIdSubscriptionCourseViewsGetQuery,
  useSubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetQuery,
  useSubscriptionsV1GetCourseViewV1TenantsTenantIdSubscriptionCourseViewsGetQuery,
  useSubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetQuery,
  useTrainingsGetTrainingsTenantsTenantIdTrainingsGetQuery,
  useTrainingsCreateTrainingTenantsTenantIdTrainingsPostMutation,
  useTrainingsGetMyTrainingsTenantsTenantIdMeTrainingsGetQuery,
  useTrainingsGetTrainingByCodeTenantsTenantIdTrainingsTrainingCodeGetQuery,
  useTrainingsArchiveTrainingTenantsTenantIdTrainingsTrainingCodeArchivePutMutation,
  useTrainingsGetTrainingDetailsByCodeTenantsTenantIdTrainingsTrainingCodeDetailsGetQuery,
  useTrainingsGetTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsGetQuery,
  useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation,
  useTrainingsExportTrainingParticipationsTenantsTenantIdTrainingsTrainingCodeParticipationsExportPostMutation,
  useTrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetQuery,
  useTrainingsAssignUsersToTrainingsTenantsTenantIdTrainingUsersPostMutation,
  useTrainingsGetTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersGetQuery,
  useTrainingsArchiveTrainingUsersTenantsTenantIdTrainingsTrainingCodeUsersArchivePutMutation,
  useTrainingsUnassignTrainingUserTenantsTenantIdTrainingsTrainingCodeUsersUnassignPutMutation,
  useTrainingsGetTrainingCyclesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesGetQuery,
  useTrainingsGetTrainingCycleTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdGetQuery,
  useNotificationTemplatesFilterTenantSpecificNotificationTemplatesTenantsTenantIdNotificationTemplatesGetQuery,
  useNotificationTemplatesCreateTenantSpecificNotificationTemplateTenantsTenantIdNotificationTemplatesPostMutation,
  useNotificationTemplatesGetTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdGetQuery,
  useNotificationTemplatesUpdateTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdPutMutation,
  useNotificationTemplatesDeleteTenantSpecificNotificationTemplateByIdTenantsTenantIdNotificationTemplatesIdDeleteMutation,
  useEmailsSendTrainingEmailsTenantsTenantIdTrainingsTrainingCodeEmailsPostMutation,
  useEmailsSendTrainingEmailsToCurrentUserTenantsTenantIdTrainingsTrainingCodeUsersMeEmailsPostMutation,
  useOdooGetTenantPartnerTenantsTenantIdOdooPartnerGetQuery,
  useCertificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGetQuery,
  useCertificatesDownloadZippedCertificatesTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdZippedCertificatesGetQuery,
  useGetManagedTenantsManagedTenantsGetQuery
} = injectedRtkApi
